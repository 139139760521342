

export function mapSheet(sheet, sheetType, color, category) {
    switch (sheetType) {
        case "monument":
            return {
                id: sheet.id,
                title: sheet.definition,
                subtitle: sheet.alternativeDenomination,
                // TEMP: remove everything after question mark including the question mark
                img: sheet.storagePreviewImage?.replace(/\?.*/, ""),
                polygon: sheet.polygon,
                color: color,
                category: category,
                table: sheetType,
                sheet: sheet
            }
        case "photo":
            // eslint-disable-next-line no-case-declarations
            let photoTitle = [
                sheet.photoInventory.map(inv => inv.code).filter(code => code != null).join(""),
                sheet.subject,
                sheet.subjectIndication,
                sheet.observations,
            ].filter(item => item != null).join(" - ");

            if (photoTitle === "") {
                photoTitle = sheet.digitalFileName;
            }

            return {
                id: sheet.documentId || sheet.id,
                title: photoTitle,
                // TEMP: remove everything after question mark including the question mark
                img: sheet.storagePreviewImage?.replace(/\?.*/, ""),
                color: color,
                category: category,
                table: sheetType,
                sheet: sheet
            }
        case "drawing":
            return {
                id: sheet.id,
                title: sheet.publicTitle || sheet.name,
                // TEMP: remove everything after question mark including the question mark
                img: sheet.storagePreviewImage?.replace(/\?.*/, ""),
                color: color,
                category: category,
                table: sheetType,
                sheet: sheet
            }
        case "document":
            return {
                id: sheet.id,
                title: sheet.publicTitle || sheet.name,
                // TEMP: remove everything after question mark including the question mark
                img: sheet.storagePreviewImage?.replace(/\?.*/, ""),
                color: color,
                category: category,
                table: sheetType,
                sheet: sheet
            }
        case "historicalArchive":
            return {
                id: sheet.id,
                title: sheet.publicTitle || sheet.date,
                // TEMP: remove everything after question mark including the question mark
                img: null,
                color: color,
                category: category,
                table: sheetType,
                sheet: sheet
            }
        case "ra":
            // eslint-disable-next-line no-case-declarations
            let title = sheet.publicTitle
                || sheet.name
                || sheet.inventoryNumber;
            // eslint-disable-next-line no-case-declarations
            let masonryTitle = (sheet.publicTitle ? (sheet.publicTitle + (sheet.raInventory?.length > 0 ? " - " + sheet.raInventory[0].number : "")) : null)
                || sheet.name
                || sheet.inventoryNumber;
            return {
                id: sheet.archaeologicalFindId,
                title: title,
                masonryTitle: masonryTitle,
                img: sheet.storagePreviewImage?.replace(/\?.*/, ""),
                color: color,
                category: category,
                table: sheetType,
                sheet: sheet
            }
        case "bibliography":
            return {
                id: sheet.id,
                title: sheet.publicTitle || sheet.title,
                img: sheet.storagePreviewImage?.replace(/\?.*/, ""),
                color: color,
                category: category,
                table: sheetType,
                sheet: sheet
            }
        default:
            return {
                id: sheet.id,
                title: sheet.publicTitle || sheet.name,
                img: sheet.storagePreviewImage?.replace(/\?.*/, ""),
                color: color,
                category: category,
                table: sheetType,
                sheet: sheet
            }
    }
}