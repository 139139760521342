<template>
  <TheHeader v-if="store.showHeader" />
  <div class="relative h-[calc(100%-64px)] w-full" id="content">
    <Transition name="vertical-menu">
      <VerticalMenu v-show="store.showVerticalMenu" />
    </Transition>
    <Transition name="the-menu-fade">
      <Menu v-show="store.showMenu" v-if="authStore.isAuthenticated" @select="store.closeMenu" />
    </Transition>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script setup>
import TheHeader from "@/components/TheHeader.vue";
import Menu from "@/components/Menu.vue";
import VerticalMenu from "@/components/VerticalMenu.vue";
import { useStore } from "@/stores/store";
import { useAuthStore } from "./stores/auth";

const store = useStore();
const authStore = useAuthStore();
</script>