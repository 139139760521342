<template>
  <div class="scheda flex gap-10 @container">
    <div class="flex w-full @[940px]:w-3/4 flex-col">
      <SheetAccordion :items="items" />
    </div>
    <div class="hidden h-[500px] w-[22%] flex-col @[750px]:flex" v-if="sheet.partOfMap && polygonArray?.length > 0">
      <p class="my-4 text-sm font-medium uppercase tracking-011">Geolocalizzazione</p>
      <VMap :leafletConfig="{ dragging: false, scrollWheelZoom: false, minZoom: 16 }" :fitBounds="true"
        :polygons="polygonArray" :customLayers="[MAP_LAYERS[0]]" ref="map" />
    </div>
  </div>
</template>
<script setup>
import { MAP_LAYERS } from "@/utils/constants";
import SheetAccordion from "../SheetAccordion.vue";
import { computed, nextTick, onMounted, onBeforeUnmount } from "vue";
import { ref } from "vue";
import { getPolygonStyles } from "@/utils/map";

const map = ref(null);


const props = defineProps({
  element: {
    type: Object,
    default: () => null,
  },
  sheet: {
    type: Object,
    default: () => null,
  },
});

const items = computed(() => {
  return props.sheet.formDropdowns.map((item) => {
    let filteredData = item.formField.filter(f => f.fieldType != 'hidden');

    function extractValue(formField, reference) {
      let value;
      if (formField.fieldType == 'select') {
        value = formField.tableOptions?.find(o => o.id === reference[formField.value])?.name
      } else if (formField.fieldType == 'multiselect' || formField.fieldType == 'exclusiveMultiselect') {
        value = reference[formField.value]?.map((e) => {
          return e[formField.parameter ? formField.parameter : 'name'];
        })
      } else {
        value = reference[formField.value];
      }

      return value;
    }

    let data = []

    filteredData.forEach((formField) => {
      if (formField.supportTable && props.element && props.element[formField.supportTable]) {
        let reference;
        if (Array.isArray(props.element[formField.supportTable]) && props.element[formField.supportTable].length > 0) {
          reference = props.element[formField.supportTable];
        } else {
          reference = [props.element[formField.supportTable]];
        }

        reference.forEach(ref => {
          let value = extractValue(formField, ref);
          data.push({
            field: formField.text,
            value: value,
            subtitle: formField.fieldType === 'subtitle' ? formField.text : null,
            supportTable: formField.supportTable
          });
          console.log(value, formField, ref);
        });
      } else {
        let value = extractValue(formField, props.element);
        console.log(value, formField, props.element);
        data.push({
          field: formField.text,
          value: value,
          subtitle: formField.fieldType === 'subtitle' ? formField.text : null
        });
      }
    })

    for (let i = 0; i < data.length; i++) {
      let supportTable = data[i].supportTable;
      if (data[i].supportTable) {
        let j = 0;

        // let's count how many fields have this supportTable
        while (data[i + j] && data[i + j].supportTable === supportTable) {
          j++;
        }

        // let's count how many different fields have this supportTable (using a dictionary with the field name as key)
        let fields = {};

        for (let k = 0; k < j; k++) {
          fields[data[i + k].field] = true;
        }

        let fieldsCount = Object.keys(fields).length;

        // now we have j / fieldsCount groups of fieldsCount fields each
        // we need to move around the fields to make them appear in the right order
        let groups = [];

        for (let k = 0; k < j / fieldsCount; k++) {
          groups.push([]);
        }

        for (let k = 0; k < j;) {
          for (let l = 0; l < fieldsCount; l++) {
            groups[k % groups.length].push(data[i + k]);
            k++;
          }
        }

        // now we have the groups, we need to put them back in the data array
        console.log(data, i, j, fieldsCount, groups);
        for (let k = 0; k < j;) {
          for (let l = 0; l < fieldsCount && k < j; l++) {
            groups[k % groups.length].push(data[i + k]);
            k++;
          }
        }

        // now we can skip the next j - 1 elements
        i += j - 1;

      }
    }
    data = data.filter(f => f.value || f.subtitle); // temporarily show all fields

    return {
      title: item.name,
      data: data
    };
  });
});

const polygonArray = computed(() => {
  return [
    {
      polygon: props.element.polygon,
      focus: true,
      style: { ...getPolygonStyles(21).monument }
    }
  ]
})

// map management
let observer;

onMounted(async () => {
  console.log(map.value);
  if (map.value) {
    console.log(map.value.map);
    await nextTick();

    // observer for map resize
    observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === map.value.$el && map.value.map) {
          map.value.map.invalidateSize();

          map.value.map.fitBounds(window.L.geoJSON(JSON.parse(props.element.polygon)).getBounds());
        }
      }
    });
    observer.observe(map.value.$el);

  }
});

onBeforeUnmount(() => {
  if (observer && map.value.$el) {
    observer.unobserve(map.value.$el);
  }
});
</script>

<style scoped>
.scheda>div {
  border-top: 1px solid var(--secondary-color);
}
</style>
