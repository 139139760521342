<template>
  <div class="collegamenti @container pt-4" v-if="props.element.linked">
    <div class="w-full flex relative gap-3 mb-8 -translate-x-4" v-if="tmpDocButtons.length">
      <button
        class="flex relative items-start text-left w-min text-[12px] mx-1 pb-[10px] uppercase leading-[15px] tracking-011"
        type="button" v-for="button in tmpDocButtons" :key="button.table" @click="changeFilter($event, button)">
        <div class="min-h-[12px] min-w-[12px] mt-0.5 mr-2 rounded-sm" :style="{ backgroundColor: '#' + button.color }">
        </div>
        <span class="flex flex-col"><span class="hidden @[840px]:block mb-1">{{ button.buttonTitle }}</span>
          <span class="@[840px]:hidden whitespace-nowrap mb-1">{{ button.abv }}</span>({{ button.links }})</span>
      </button>
      <hr ref="line" class="absolute bottom-0 border-none left-0 translate-x-5 bg-neutral-800 w-9 h-[3px]">
    </div>
    <MasonryList class="overflow-y-hidden" :items="displayedItems" ref="masonry"
      @load-more="changePage(currentPage + 1)" scroll-container="sheet" />
    <p class="font-normal text-[#00000069] text-center mt-3" v-if="tmpDocButtons.filter(b => b.links > 0).length == 0">
      In questa scheda non sono presenti collegamenti</p>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import MasonryList from "@/components/MasonryList.vue";
import { useDocumentationStore } from "@/stores/documentation";
import { mapSheet } from "@/utils/data";
import { gsap } from "gsap";

const props = defineProps({
  element: { type: Object, default: null },
  openLinks: { type: Boolean, default: true }
})
const emit = defineEmits(['action'])

const docStore = useDocumentationStore();
const docButtons = ref([])
const tmpDocButtons = ref([])
const line = ref(null)
const currentFilter = ref('')
const masonry = ref(null)

// pagination
const displayedItems = ref([])
const currentPage = ref(0)
const itemsPerPage = 15
const totalItems = computed(() => props.element.linked.length)
const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage))

function changePage(page, filterChange = false) {
  console.log('change page', currentPage.value, page, filterChange)
  const nextPage = Math.max(0, Math.min(page, totalPages.value));

  currentPage.value = nextPage;

  const startIndex = currentPage.value * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newItems = props.element.linked?.filter(e => e.meta.dataType === currentFilter.value || !currentFilter.value)
    .slice(startIndex, endIndex)
    .map(e => {
      let sheet = docStore.sheets.find(s => s.table === e.meta.dataType);
      return {
        onClick: () => {
          if (props.openLinks) {
            emit('action', { type: 'openSheet', payload: { sheet: sheet, element: e.data } })
          }
        },
        ...mapSheet(e.data, e.meta.dataType, sheet.color, sheet.buttonTitle.toUpperCase())
      }
    });

  displayedItems.value = filterChange ? [...newItems] : [...displayedItems.value, ...newItems];
  currentPage.value = nextPage;

  // masonry reload
  setTimeout(() => {
    try {
      masonry.value.masonryEl.parentElement.scroll(0, 0)
      masonry.value.masonry.reloadItems()
      masonry.value.masonry.layout()
    } catch (e) {
      console.log(e)
    }
  }, 100);
}

const changeFilter = (e, button) => {
  let el = e.target
  if (e.target.tagName !== 'BUTTON') el = el.parentElement;
  if (el.tagName !== 'BUTTON') el = el.parentElement;
  let elx = el.querySelector('span').getBoundingClientRect().x;
  let linex = line.value.getBoundingClientRect().x - 20;
  gsap.to(line.value, {
    x: elx - linex, duration: 0.4, onComplete: () => {
      gsap.set(line.value, { x: 20 })
      el.appendChild(line.value)
    }
  })
  gsap.to(line.value, { scaleX: 1.6, duration: 0.2 })
  gsap.to(line.value, { scaleX: 1, duration: 0.2, delay: 0.2 })
  currentFilter.value = button.table;

  changePage(0, true)

  // masonry reload
  setTimeout(() => {
    masonry.value.masonryEl.parentElement.scroll(0, 0)
    masonry.value.masonry.reloadItems()
    masonry.value.masonry.layout()
  }, 500)
}

onBeforeMount(async () => {
  await docStore.fetchSheetsMetadata();
  await docStore.fetchAreas();
  docButtons.value = docStore.getSheets.filter((item) => item.order);
  console.log('docButtons', docButtons.value)
  tmpDocButtons.value = [
    {
      table: null,
      buttonTitle: "Tutto",
      iconURL: null,
    }
  ];

  docButtons.value.forEach((button) => {
    tmpDocButtons.value.push({ ...button });
  });

  let abv = ["Tutto", "Mon.", "Rep.", "Doc. Sc.", "Biblio.", "A. Fot.", "A. Cart.", "Minp"]
  tmpDocButtons.value.forEach((button, index) => {
    if (index === 0) button.links = props.element.linked.length;
    else button.links = props.element.linked.filter((link) => link.meta.dataType === button.table).length;
    button.abv = abv[index]
  });
  tmpDocButtons.value = tmpDocButtons.value.filter(b => b.links > 0)

  changePage(0)
});
</script>

<style>
.collegamenti {
  border-top: 1px solid var(--secondary-color);
}

.collegamenti .masonry-wrapper .category>p {
  font-weight: 500;
}
</style>
